var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c(_vm.resolveLayout, { tag: "component" }, [_c("router-view")], 1),
      _c(
        "v-footer",
        { attrs: { padless: "", dense: "", color: "primary", dark: "" } },
        [
          _c(
            "v-col",
            { staticClass: "text-center text-caption", attrs: { cols: "12" } },
            [
              _vm._v(" Copyright © " + _vm._s(new Date().getFullYear()) + " "),
              _c("strong", [_vm._v("numah.world")]),
              _c("v-spacer"),
              _c("div", { staticStyle: { "font-size": "smaller" } }, [
                _vm._v(" v" + _vm._s(_vm.appVersion) + " "),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("Toasts", { attrs: { text: _vm.message, color: "success" } }),
      _c("Toasts", { attrs: { text: _vm.error, color: "error" } }),
      _c("BasicToasts"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }