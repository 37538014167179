<template>
  <v-app>
    <component :is="resolveLayout">
      <router-view />
    </component>

    <v-footer padless dense color="primary" dark>
      <v-col class="text-center text-caption" cols="12">
        Copyright &copy; {{ new Date().getFullYear() }} <strong>numah.world</strong>
        <v-spacer />
        <div style="font-size: smaller">
          v{{ appVersion }}
        </div>
      </v-col>
    </v-footer>

    <Toasts :text="message" color="success" />
    <Toasts :text="error" color="error" />
    <BasicToasts />
  </v-app>
</template>

<script>
import { components as auroraAppCore, authHub, errorHub } from '@codehq/aurora-app-core/src';
import { mapMutations } from 'vuex';
import LayoutContent from './layouts/Content.vue';
import LayoutBlank from './layouts/Blank.vue';
import LayoutPublic from './layouts/Public.vue';
import pkg from '../package.json';
import './site.scss';

const {
  BasicToasts, Toasts,
} = auroraAppCore;

export default {
  name: 'App',
  data() {
    return {
      message: '',
      error: '',
    };
  },
  components: {
    BasicToasts,
    LayoutBlank,
    LayoutContent,
    LayoutPublic,
    Toasts,
  },
  computed: {
    appVersion() {
      return pkg.version;
    },
    resolveLayout() {
      // Handles initial route
      if (this.$route.name === null) return null;

      if (this.$route.meta.layout === 'blank') return 'layout-blank';
      if (this.$route.meta.layout === 'public') return 'layout-public';

      return 'layout-content';
    },
  },
  created() {
    // this.$vuetify.theme.dark = true;
    this.$root.$on('organizations.loaded', (organizations) => {
      if (organizations.length === 0 && this.isLoggedIn) {
        this.$router.push({
          name: 'app-Onboard',
        });
      }
    });
    this.$root.$on('organization.saved', (organization) => {
      this.SET_organization(organization);
      this.$router.push({
        name: 'rental-properties-EditRentalProperty',
        params: {
          initial: true,
        },
      });
    });
    errorHub.$on('network.error', () => {
      this.$root.$emit('network.error');
    });
    authHub.$on('user.unauthorized', async () => {
      this.msalApplication.logout();
      await this.$router.push('/auth/login');
      window.location.reload();
    });
    this.$root.$on('toast:error', (err) => {
      this.error = '';
      this.error = err;
    });
    this.$root.$on('auth:login', () => {
      this.$root.$emit('toast:notify', 'Logged in successfully');
    });
  },
  destroyed() {
    authHub.$off('user.unauthorized');
    this.$root.$off('auth:login');
  },
  methods: {
    ...mapMutations('organizations', ['SET_organization']),
    async installExtension() {
      this.$confirm('Are you sure you want to install the chrome extension?');
    },
    logout() {
      this.$root.$emit('auth:logoff');
    },
  },
};
</script>
