var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    [
      _vm.chartData.length > 1
        ? _c(
            "v-col",
            { attrs: { cols: _vm.isVertical ? "12" : "6" } },
            [
              _c("div", { staticClass: "text-subtitle-2 text-center" }, [
                _vm._v(" Reasons for Stopping "),
              ]),
              _c("GChart", {
                attrs: {
                  type: "PieChart",
                  data: _vm.chartData,
                  options: _vm.chartOptions,
                  resizeDebounce: 500,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      !_vm.hideExplanations && _vm.chartData2.length > 1
        ? _c(
            "v-col",
            { attrs: { cols: _vm.isVertical ? "12" : "6" } },
            [
              _c("div", { staticClass: "text-subtitle-2 text-center" }, [
                _vm._v(" Explanations "),
              ]),
              _c("GChart", {
                attrs: {
                  type: "PieChart",
                  data: _vm.chartData2,
                  options: _vm.chartOptions2,
                  resizeDebounce: 500,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }