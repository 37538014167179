import Vue from 'vue';
import VueRouter from 'vue-router';
import { kebabCase } from 'case-anything';

import appViews from '../views';
import guards from './guards';
import modules from '../modules';

const Init = () => import(/* webpackChunkName: "route-Init" */ '../views/Init.vue');
const Login = () => import(/* webpackChunkName: "route-Login" */ '../views/Login.vue');
const CandidateUpload = () => import(/* webpackChunkName: "route-CandidateUpload" */ '../views/CandidateUpload.vue');

Vue.use(VueRouter);

const mapViews = (prefix, views) => {
  const viewsArr = Object.keys(views)
    .filter((v) => views[v].name !== 'Home')
    .map((v) => ({
      path: `/${prefix}/${kebabCase(v)}`,
      name: `${prefix}-${v}`,
      component: views[v],
      meta: views[v].meta,
    }));
  return viewsArr;
};

const appRoutes = [
  {
    path: '/auth/login',
    alias: '/login',
    name: 'auth-Login',
    component: Login,
    meta: {
      layout: 'public',
    },
  },
  {
    path: '/candidate-upload',
    name: 'candidate-upload',
    component: CandidateUpload,
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/init',
    name: 'app-Init',
    component: Init,
  },
  {
    path: '/admin/users',
    name: 'admin-Users',
    component: appViews.UserManagementHome,
    meta: { requiresAuth: true },
  },
  {
    path: '/admin/jobs',
    name: 'admin-Jobs',
    component: appViews.ScheduledJobs,
    meta: { requiresAuth: true },
  },
  {
    path: '/admin/candidate-upload-settings',
    name: 'admin-CandidateUploadSettings',
    component: appViews.CandidateUploadSettings,
    meta: { requiresAuth: true },
  },
  {
    path: '/admin/event-logs',
    name: 'admin-EventLog',
    component: appViews.AdminEventLog,
    meta: { requiresAuth: true },
  },
  {
    path: '/admin/messages-exchange',
    name: 'admin-MessagesExchange',
    component: appViews.MessageExchange,
    meta: { requiresAuth: true },
  },
  {
    path: '/admin/notifications',
    name: 'admin-Notifications',
    component: appViews.ListNotifications,
    meta: { requiresAuth: true },
  },
  {
    path: '/admin/job-listing-statuses',
    name: 'admin-ListJobListingStatus',
    component: appViews.ListJobListingStatus,
    meta: { requiresAuth: true },
  },
  {
    path: '/admin/listing-status',
    name: 'admin-EditJobListingStatus',
    component: appViews.EditJobListingStatus,
    meta: { requiresAuth: true },
  },
  {
    path: '/invite/accept',
    name: 'app-InviteAccept',
    component: appViews.AcceptInvite,
  },
  {
    path: '/teams',
    name: 'teams-View',
    component: appViews.Teams,
    meta: { requiresAuth: true },
  },
  {
    path: '/admin/consent',
    name: 'app-Consent',
    component: appViews.AdminConsent,
    meta: { requiresAuth: true },
  },
  {
    path: '/profile',
    name: 'app-Profile',
    component: appViews.Profile,
    meta: { requiresAuth: true },
  },
  {
    path: '/admin/feedback-items',
    name: 'admin-FeedbackItems',
    component: appViews.FeedbackItems,
    meta: { requiresAuth: true },
  },
  {
    path: '/admin/mail-server',
    name: 'admin-MailServer',
    component: appViews.MailServer,
    meta: { requiresAuth: true },
  },
  {
    path: '/linked-in',
    name: 'candidates-LinkedIn',
    component: appViews.LinkedIn,
    meta: { requiresAuth: true },
  },
  {
    path: '/home',
    alias: '/',
    name: 'app-Home',
    component: appViews.Home,
    meta: { requiresAuth: true, requiresOrg: true },
  },
];

const moduleRoutes = modules.map((m) => mapViews(m.config.path, m.views)).flat();
const routes = [...appRoutes, ...moduleRoutes];

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach(guards);

export { routes };
export { router };
