var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "", "grid-list-xl": "", "fill-height": "" } },
    [
      _c(
        "v-layout",
        { attrs: { "justify-center": "", "align-start": "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c(
                "v-skeleton-loader",
                {
                  attrs: {
                    loading: _vm.isLoading,
                    transition: "scale-transition",
                    type: "list-item-two-line",
                  },
                },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-form",
                            { attrs: { "lazy-validation": false } },
                            [
                              _c("v-textarea", {
                                attrs: {
                                  label: "Welcome Message",
                                  outlined: "",
                                },
                                model: {
                                  value: _vm.welcomeMessage,
                                  callback: function ($$v) {
                                    _vm.welcomeMessage = $$v
                                  },
                                  expression: "welcomeMessage",
                                },
                              }),
                              _c("vue-editor", {
                                attrs: { label: "GDPR Message" },
                                model: {
                                  value: _vm.gdprMessage,
                                  callback: function ($$v) {
                                    _vm.gdprMessage = $$v
                                  },
                                  expression: "gdprMessage",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-card-actions",
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: { loading: _vm.isSaving },
                              on: { click: _vm.save },
                            },
                            [_vm._v("Save")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }