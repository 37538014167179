var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-tabs",
    { attrs: { loading: _vm.isLoading } },
    [
      _c(
        "v-tab",
        [
          _c("v-icon", { staticClass: "mr-1" }, [
            _vm._v(_vm._s(_vm.mdiComment)),
          ]),
          _vm._v(" Comment "),
        ],
        1
      ),
      _c(
        "v-tab",
        [
          _c("v-icon", { staticClass: "mr-1" }, [_vm._v(_vm._s(_vm.mdiEmail))]),
          _vm._v(" Email "),
        ],
        1
      ),
      _c(
        "v-tab",
        [
          _c("v-icon", { staticClass: "mr-1" }, [
            _vm._v(_vm._s(_vm.mdiMessageProcessing)),
          ]),
          _vm._v(" Text "),
        ],
        1
      ),
      _c(
        "v-tab",
        [
          _c("v-icon", { staticClass: "mr-1" }, [
            _vm._v(_vm._s(_vm.mdiWhatsapp)),
          ]),
          _vm._v(" WhatsApp "),
        ],
        1
      ),
      _c(
        "v-tab",
        [
          _c("v-icon", { staticClass: "mr-1" }, [_vm._v(_vm._s(_vm.mdiPhone))]),
          _vm._v(" Phone Call "),
        ],
        1
      ),
      _c(
        "v-tab-item",
        [
          _c(
            "v-form",
            [
              _c("v-textarea", {
                attrs: { label: "Message", counter: "2000", limit: "2000" },
                scopedSlots: _vm._u([
                  {
                    key: "append",
                    fn: function () {
                      return [
                        _c("AutoResponse", {
                          on: {
                            input: function ($event) {
                              _vm.form.message = $event
                            },
                          },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ]),
                model: {
                  value: _vm.form.message,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "message", $$v)
                  },
                  expression: "form.message",
                },
              }),
              _c(
                "v-btn",
                {
                  on: {
                    click: function ($event) {
                      return _vm.sendMessage("comment")
                    },
                  },
                },
                [_vm._v(" Comment ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-tab-item",
        [
          _c(
            "v-form",
            [
              _c("v-text-field", {
                attrs: { label: "Email Address" },
                model: {
                  value: _vm.form.email,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "email", $$v)
                  },
                  expression: "form.email",
                },
              }),
              _c("v-text-field", {
                attrs: { label: "Subject" },
                model: {
                  value: _vm.form.subject,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "subject", $$v)
                  },
                  expression: "form.subject",
                },
              }),
              _c(
                "div",
                { staticClass: "d-flex justify-space-between mb-2" },
                [
                  _c("EmailAttachments", {
                    model: {
                      value: _vm.form.attachments,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "attachments", $$v)
                      },
                      expression: "form.attachments",
                    },
                  }),
                  _c("AutoResponse", {
                    on: { input: _vm.insertHtmlAutoResponse },
                  }),
                ],
                1
              ),
              _c("vue-editor", {
                staticClass: "black--text",
                attrs: { label: "Message", "auto-grow": "", rows: "5" },
                scopedSlots: _vm._u([
                  {
                    key: "append",
                    fn: function () {
                      return undefined
                    },
                    proxy: true,
                  },
                ]),
                model: {
                  value: _vm.form.htmlMessage,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "htmlMessage", $$v)
                  },
                  expression: "form.htmlMessage",
                },
              }),
              _c(
                "div",
                { staticClass: "d-flex justify-space-between mt-2" },
                [
                  _c("PreviewEmail", {
                    attrs: { label: "Preview", message: _vm.emailMessage },
                  }),
                  _c(
                    "v-btn",
                    { on: { click: _vm.sendMail } },
                    [
                      _c("v-icon", { staticClass: "mr-2" }, [
                        _vm._v(" " + _vm._s(_vm.mdiEmailFast) + " "),
                      ]),
                      _vm._v(" Send "),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-checkbox", {
                attrs: { label: "Save Only" },
                model: {
                  value: _vm.form.saveOnly,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "saveOnly", $$v)
                  },
                  expression: "form.saveOnly",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-tab-item",
        [
          _c(
            "v-form",
            [
              _c("v-select", {
                attrs: {
                  items: _vm.twilioNumbers,
                  "item-text": (i) => `${i.country} - ${i.number}`,
                  "item-value": (i) => i.number,
                  label: "From Number",
                },
                on: {
                  change: function ($event) {
                    _vm.form.from = _vm.fromNumber
                  },
                },
                model: {
                  value: _vm.fromNumber,
                  callback: function ($$v) {
                    _vm.fromNumber = $$v
                  },
                  expression: "fromNumber",
                },
              }),
              _c("v-text-field", {
                attrs: {
                  label: "Phone number",
                  placeholder: "Recipient's phone number",
                },
                on: { change: _vm.onPhoneNumberChanged },
                model: {
                  value: _vm.form.recipient,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "recipient", $$v)
                  },
                  expression: "form.recipient",
                },
              }),
              _c("v-textarea", {
                attrs: {
                  "auto-grow": "",
                  label: "Message",
                  placeholder: "Body of your message",
                },
                scopedSlots: _vm._u([
                  {
                    key: "append",
                    fn: function () {
                      return [
                        _c("AutoResponse", {
                          on: {
                            input: function ($event) {
                              _vm.form.message = $event
                            },
                          },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ]),
                model: {
                  value: _vm.form.message,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "message", $$v)
                  },
                  expression: "form.message",
                },
              }),
              _c(
                "v-btn",
                {
                  attrs: { disabled: !_vm.fromNumber },
                  on: {
                    click: function ($event) {
                      return _vm.sendMessage("text")
                    },
                  },
                },
                [_vm._v(" Send ")]
              ),
              _c("v-checkbox", {
                attrs: { label: "Save Only" },
                model: {
                  value: _vm.form.saveOnly,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "saveOnly", $$v)
                  },
                  expression: "form.saveOnly",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-tab-item",
        [
          _c(
            "v-form",
            [
              _c("v-select", {
                attrs: {
                  items: _vm.twilioNumbers,
                  "item-text": (i) => `${i.country} - ${i.number}`,
                  "item-value": (i) => i.number,
                  label: "From Number",
                },
                on: {
                  change: function ($event) {
                    _vm.form.from = _vm.fromNumber
                  },
                },
                model: {
                  value: _vm.fromNumber,
                  callback: function ($$v) {
                    _vm.fromNumber = $$v
                  },
                  expression: "fromNumber",
                },
              }),
              _c("v-text-field", {
                attrs: {
                  label: "Phone number",
                  placeholder: "Recipient's phone number",
                },
                on: { change: _vm.onPhoneNumberChanged },
                model: {
                  value: _vm.form.recipient,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "recipient", $$v)
                  },
                  expression: "form.recipient",
                },
              }),
              _c("v-textarea", {
                attrs: {
                  "auto-grow": "",
                  label: "Message",
                  placeholder: "Body of your message",
                },
                scopedSlots: _vm._u([
                  {
                    key: "append",
                    fn: function () {
                      return [
                        _c("AutoResponse", {
                          on: {
                            input: function ($event) {
                              _vm.form.message = $event
                            },
                          },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ]),
                model: {
                  value: _vm.form.message,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "message", $$v)
                  },
                  expression: "form.message",
                },
              }),
              _c(
                "v-btn",
                {
                  attrs: { disabled: !_vm.fromNumber },
                  on: {
                    click: function ($event) {
                      return _vm.sendMessage("whatsApp")
                    },
                  },
                },
                [_vm._v(" Send ")]
              ),
              _c("v-checkbox", {
                attrs: { label: "Save Only" },
                model: {
                  value: _vm.form.saveOnly,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "saveOnly", $$v)
                  },
                  expression: "form.saveOnly",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-tab-item",
        [
          _c(
            "v-form",
            [
              _c("v-textarea", {
                attrs: {
                  "auto-grow": "",
                  label: "Comments",
                  placeholder: "Comments on the call",
                },
                scopedSlots: _vm._u([
                  {
                    key: "append",
                    fn: function () {
                      return [
                        _c("AutoResponse", {
                          on: {
                            input: function ($event) {
                              _vm.form.message = $event
                            },
                          },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ]),
                model: {
                  value: _vm.form.message,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "message", $$v)
                  },
                  expression: "form.message",
                },
              }),
              _c(
                "v-btn",
                {
                  on: {
                    click: function ($event) {
                      return _vm.sendMessage("phoneCall")
                    },
                  },
                },
                [_vm._v(" Send ")]
              ),
              _c("v-checkbox", { attrs: { label: "Save Only" } }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }