<template>
  <v-container fill-height>
    <v-row class="background justify-center align-center mt-6" no-gutters>
      <v-col cols="12" lg="6" xl="6">
        <v-card flat v-if="isLoggingIn">
          <v-card-text>
            <v-row justify="center">
              <v-col cols="12" class="text-center">
                <div v-if="account" class="text-h4 d-block">
                  Welcome {{ account.name }}, we're just
                </div>
                <div class="text-h5 text-h4 d-block">{{ loginText }}...</div>
              </v-col>
              <v-col cols="12" class="text-center">
                <ProgressLinearTimeout />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card v-else flat class="ma-3 pa-4">
          <v-container>
            <v-row class="mt-3" justify="center" no-gutters>
              <v-col cols="12">
                <v-card flat>
                  <v-img class="ml-auto mr-auto" src="../assets/numaH_Logo_New.png" width="229" />
                  <v-card-text>
                    <v-btn block color="success" @click="login" :loading="isLoading">
                      <v-icon class="mr-2">{{ mdiMicrosoftOffice }}</v-icon>
                      Microsoft Login
                    </v-btn>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mdiOpenInNew, mdiMicrosoftOffice } from '@mdi/js';
import { mapActions, mapMutations } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import ProgressLinearTimeout from '@codehq/aurora-app-core/src/components/ProgressLinearTimeout.vue';
import { useAuth } from '../use';

const auth = useAuth();

export default {
  name: 'login',
  mixins: [loading],
  components: {
    ProgressLinearTimeout,
  },
  data() {
    return {
      mdiOpenInNew,
      mdiMicrosoftOffice,
      isLoggingIn: false,
      loginText: 'Logging you in',
      account: undefined,
    };
  },
  async mounted() {
    const account = await auth.userAgentApplication.getAccount();
    this.account = account;
    if (account) {
      this.isLoggingIn = true;
      // register
      this.$log.debug('logged in, registering user');
      this.loginText = 'Setting up user';
      try {
        await this.$http.post('/api/external-account/register');
      } catch (error) {
        this.$root.$emit('toast:error', 'Login failed');
        this.SET_isLoggedIn(false);
        this.SET_username('');
        this.SET_userId('');
        this.clearAllCookiesAndSessionStorage();
        auth.logout();
        auth.userAgentApplication?.logout();
        this.$router.push('/');
        return;
      }

      this.loginText = 'Loading user info';
      // login
      this.$log.debug('logged in, loading user info');
      const { data } = await this.$http.get('/api/org');

      this.loginText = 'Loading organizations';
      this.$log.debug('logged in, loading organizations');
      // load orgs
      await this.LOAD_organizations();

      this.SET_isLoggedIn(true);
      const roles = data.userRoles?.join(';');
      this.SET_username(data.user);
      this.SET_userId(data.userId);
      this.SET_roles(roles);
      this.SET_provider('azure');
      this.$log.info('logged in, redirecting');
      this.loginText = 'Starting';
      await this.$nextTick();
      this.$router.push({ name: 'app-Home' });
    }
  },
  methods: {
    ...mapActions('organizations', ['LOAD_organizations']),
    ...mapMutations('auth', ['SET_isLoggedIn', 'SET_roles', 'SET_username', 'SET_userId', 'SET_provider']),
    clearAllCookiesAndSessionStorage() {
      // Remove all cookies.
      const cookies = document.cookie.split(';');
      // eslint-disable-next-line no-restricted-syntax
      for (const cookie of cookies) {
        document.cookie = cookie.replace(/^.+?=\s*?(.*?);$/, '; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/');
      }

      // Remove all session storage items.
      sessionStorage.clear();
    },
    login() {
      this.isLoading = true;
      try {
        this.$log.debug('logging in');
        auth.login();
      } catch (error) {
        if (error?.response?.data?.innerException?.exceptionMessage) {
          this.$root.$emit('toast:error', error?.response?.data?.innerException?.exceptionMessage);
        } else if (error?.response?.data?.exceptionMessage) {
          this.$root.$emit('toast:error', error?.response?.data?.exceptionMessage);
        } else {
          this.$root.$emit('toast:error', 'Unable to login');
        }
        this.$log.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
