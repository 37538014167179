var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { "fill-height": "" } },
    [
      _vm.isLoading
        ? _c("ProgressLinearTimeout")
        : _c(
            "v-layout",
            { attrs: { "justify-center": "", "align-start": "" } },
            [
              _c(
                "v-card",
                { staticClass: "fill-width" },
                [
                  _c("v-card-title", [_vm._v("Candidate Upload")]),
                  _c("v-card-subtitle", [_vm._v(_vm._s(_vm.welcomeMessage))]),
                  _c(
                    "v-card-text",
                    [
                      _c("p", {
                        domProps: { innerHTML: _vm._s(_vm.gdprMessage) },
                      }),
                      !_vm.uploadedFileUrl
                        ? _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c("FileUpload", {
                                    attrs: {
                                      accept: _vm.documentAccept,
                                      label: "Upload Document",
                                      icon: _vm.mdiFileDocumentOutline,
                                    },
                                    on: { input: _vm.uploadFile },
                                  }),
                                ],
                                1
                              ),
                              _vm.uploadedFiles.length > 0
                                ? _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _vm._v(" Thank for uploading: "),
                                      _c(
                                        "v-list",
                                        _vm._l(
                                          _vm.uploadedFiles,
                                          function (file) {
                                            return _c(
                                              "v-list-item",
                                              { key: file.fileName },
                                              [
                                                _c(
                                                  "v-list-item-content",
                                                  [
                                                    _c("v-list-item-title", [
                                                      _vm._v(
                                                        _vm._s(file.fileName)
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _c("p", [
                            _c("b", [
                              _vm._v("Thank you for uploading your document."),
                            ]),
                          ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }